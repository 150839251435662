import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import ekey from "./ekey.js";
//import { BrowserRouter as Router, Route } from "react-router-dom";
import { HashRouter as Router, Route } from "react-router-dom";

const Home = () => (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <h1 className="App-title">阔道超级锁</h1>
    </header>
    <p className="App-intro">链接远方的你我</p>
  </div>
);

class App extends Component {
  componentDidMount() {
    console.log("mount");
  }

  componentWillUnmount() {
    console.log("unmount");
  }

  render() {
    return (
      <Router basename=".">
        <div>
          <Route path="/" exact component={Home} />
          <Route path="/:id" component={ekey} />
        </div>
      </Router>
    );
  }
}

export default App;
