/*@flow*/
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import ReactSwipe from "react-swipe";
import Img from "react-image";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { isIOS } from "react-device-detect";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";

//const isIOS = true;
const Sound = require("react-sound").default;

const kdwareInterfaceHost = "https://clb.kdware.cn:5000";
//const kdwareInterfaceHost = "http://172.168.2.165:5000";
const kdwareVoiceHost = "https://clb.kdware.cn:5003";
//const kdwareVoiceHost = "http://172.168.2.165:5003";

const DeviceType = {
  R39: 1,
  P91: 2,
  C91: 3,
  S90: 4,
  F80: 5,
};

const loadEkey = (ekeyID, viewer) => {
  viewer.setState({ loading: true });
  console.log("loading data for ekey:" + ekeyID);

  return fetch(kdwareInterfaceHost + "/v1.1/loadEkey", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      EkeyID: ekeyID,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.hasOwnProperty("resultCode")) {
        console.log("load fail:", res);
        viewer.setState({ loadError: "Invalid EKey", loading: false });
      } else {
        let deviceArray = [];
        res.details.forEach((detail) => {
          deviceArray.push({
            id: detail.deviceID,
            name: detail.deviceName,
            type: detail.deviceType,
          });
        });
        const date = moment(res.endDate);
        // let date = new Date(res.endDate);
        let formatDate =
          date.year() +
          "年" +
          (1 + date.month()) +
          "月" +
          date.date() +
          "日 " +
          date.hour() +
          "时" +
          date.minute() +
          "分";

        viewer.setState({
          loading: false,
          owner: res.owner,
          endDate: formatDate,
          devices: deviceArray,
        });
      }

      viewer.onSwipeChange(0);
    })
    .catch((err) => {
      console.log("load error:", err);
      viewer.setState({ loadError: err, loading: false });
    });
};

const unlockEkey = (ekeyID, devID, viewer) => {
  viewer.setState({ unlockProgress: "正在执行中" });

  return fetch(kdwareInterfaceHost + "/v1.1/unlockEkey", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      EkeyID: ekeyID,
      deviceID: devID.toString(),
      lockState: 2,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.hasOwnProperty("resultCode") && res.resultCode !== 0) {
        console.log("unlock fail:", res);
        viewer.setState({ unlockError: "Invalid EKey" });
      } else {
        console.log("unlock done:", res);
        viewer.setState({ unlockDone: true });
      }
      viewer.setState({ unlockProgress: "" });
    })
    .catch((err) => {
      console.log("unlock error:", err);
      viewer.setState({ unlockError: err });
    });
};

const unlockVoiceEkey = (ekeyID, devID, viewer) => {
  viewer.setState({ unlockProgress: "正在加载中" });

  //http://www.kdware.cn:5003/s9xVoice?EkeyID=2cXzCcwxvsTDZ90usORUwpsQtVCT4F8wrVmOZY9lChvfK1I-x-Z8HtPoWuPJHCws&deviceID=11791
  try {
    viewer.setState({
      s9xUrl:
        kdwareVoiceHost + "/s9xVoice?EkeyID=" + ekeyID + "&deviceID=" + devID,
    });
  } catch (error) {
    console.log("error:", error);
  }
};

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    textAlign: "center",
  },
  button: {
    margin: theme.spacing.unit,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
});

class EKey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadError: null,
      unlockError: null,
      unlockDone: false,
      owner: null,
      endDate: null,
      devices: [],
      s9xUrl: "",
      s9xPlayStatus: Sound.status.STOPPED,
      unlockProgress: "",
      activeDevName: null,
      activeSwipeItemIndex: 0,
    };
  }

  componentDidMount() {
    console.log("mount", window);
    this.setState({}, () => {
      loadEkey(this.props.match.params.id, this);
    });
  }

  componentWillUnmount() {
    console.log("unmount");
  }

  next = () => {
    this.reactSwipe.next();
  };

  prev = () => {
    this.reactSwipe.prev();
  };

  onSwipeChange = (index) => {
    if (this.state.devices.length === 0) {
      this.setState({
        activeDevName: "无对应设备,请联系管理员",
        unlockProgress: "",
        s9xPlayStatus: Sound.status.STOPPED,
        s9xUrl: "",
        activeSwipeItemIndex: index,
      });
    } else {
      let device = this.state.devices[index];
      console.log("cur device:", device.name);
      this.setState({
        activeDevName: device.name,
        unlockProgress: "",
        s9xPlayStatus: Sound.status.STOPPED,
        s9xUrl: "",
        activeSwipeItemIndex: index,
      });
    }
  };

  handleClose = () => {};

  render() {
    console.log("render");
    const swipeItems = this.state.devices.map((device) => {
      if (
        device.type === DeviceType.S90 &&
        this.state.s9xPlayStatus === Sound.status.PLAYING
      ) {
        return (
          <div
            key={device.id}
            style={{
              marginTop: "20px",
            }}
            className="item"
          >
            <Img src="images/voice_unlock.gif" />
          </div>
        );
      } else {
        return (
          <div
            key={device.id}
            style={{
              marginTop: "20px",
            }}
            className="item"
          >
            <Button
              color="secondary"
              variant="fab"
              style={{ marginTop: 20, width: "150px", height: "150px" }}
              onClick={() => {
                if (this.state.devices.length !== 0) {
                  let device = this.state.devices[this.reactSwipe.getPos()];
                  if (device.type === DeviceType.S90) {
                    unlockVoiceEkey(
                      this.props.match.params.id,
                      device.id,
                      this
                    );
                    this.setState({
                      unlockProgress: "准备播放...",
                      s9xPlayStatus: Sound.status.PLAYING,
                    });
                  } else {
                    unlockEkey(this.props.match.params.id, device.id, this);
                  }
                }
              }}
              className={this.props.classes.button}
            >
              <LockOpenIcon style={{ width: "80px", height: "80px" }} />
            </Button>
          </div>
        );
      }
    });

    if (this.state.loadError != null) {
      return (
        <div className={this.props.classes.root}>
          <header>
            <h1>阔道超级锁</h1>
          </header>
          <p>该电子钥匙无效，请联系管理员</p>
        </div>
      );
    } else {
      let lockName = this.state.activeDevName;
      if (
        !!this.state.activeDevName &&
        this.state.activeDevName.startsWith("/ROOM/")
      ) {
        const devNames = this.state.activeDevName.split("/");
        if (!!devNames && devNames.length >= 5) {
          lockName = devNames[4];
        }
      }
      let ownerName = this.state.owner;
      if (!!this.state.owner && this.state.owner.startsWith("/KEY/")) {
        const owners = this.state.owner.split("/");
        if (!!owners && owners.length >= 3) {
          ownerName = owners[2];
        }
      }
      return (
        <Grid container className={this.props.classes.root} spacing={16}>
          <Grid item xs={12}>
            <Sound
              url={this.state.s9xUrl}
              volume={100}
              autoLoad={true}
              playFromPosition={0}
              onLoad={(result) => {
                console.log("loading done:", result);
                if (!isIOS) {
                  if (result) {
                    this.setState({
                      unlockProgress: "",
                      s9xPlayStatus: Sound.status.PLAYING,
                    });
                  } else {
                    this.setState({ unlockProgress: "加载失败" });
                  }
                }
              }}
              playStatus={this.state.s9xPlayStatus}
              onLoading={() => {
                console.log("onloading...");
                if (isIOS && this.state.unlockProgress !== "") {
                  this.setState({
                    unlockProgress: "",
                  });
                }
              }}
              onPlaying={() => {
                console.log("onplaying...");
                if (isIOS && this.state.unlockProgress !== "") {
                  this.setState({
                    unlockProgress: "",
                  });
                }
              }}
              onFinishedPlaying={() => {
                console.log("on finish playing");
              }}
              loop={true}
            />
            <Paper className={this.props.classes.root} elevation={1}>
              <Typography variant="headline" component="h3">
                当前门锁:
                {lockName}
              </Typography>
              <Typography component="p">
                有效期至:
                {this.state.endDate}
              </Typography>
              <Typography component="p">
                持有人名:
                {ownerName}
              </Typography>
            </Paper>
            <ReactSwipe
              ref={(el) => (this.reactSwipe = el)}
              className="mySwipe"
              key={this.state.devices.length}
              childCount={this.state.devices.length}
              swipeOptions={{
                startSlide: this.state.activeSwipeItemIndex,
                continuous: true,
                callback: () => {
                  console.log("slide changed");
                  console.log("pos=", this);
                },
                transitionEnd: () => {
                  console.log("ended transition");
                  this.onSwipeChange(this.reactSwipe.getPos());
                },
              }}
            >
              {swipeItems}
            </ReactSwipe>
            <Fade
              in={this.state.unlockProgress !== ""}
              style={{
                transitionDelay:
                  this.state.loading === "progress" ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
            <div
              style={{
                opacity: 0.5,
              }}
            >
              <Grid
                container
                className={this.props.classes.control}
                justify="center"
                spacing={40}
              >
                <Grid key={0} item>
                  <Button
                    disabled={this.state.devices.length < 2}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.prev();
                    }}
                  >
                    上一个锁
                  </Button>
                </Grid>
                <Grid key={1} item>
                  <Button
                    disabled={this.state.devices.length < 2}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.next();
                    }}
                  >
                    下一个锁
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                opacity: 0.5,
              }}
            >
              <strong>版本:1.0.0.202308041123</strong>
            </div>
            <Snackbar
              open={this.state.unlockError !== null || this.state.unlockDone}
              onClose={() => {
                this.setState({ unlockError: null, unlockDone: false });
              }}
              autoHideDuration={this.state.unlockDone ? 800 : 1200}
              transition={Fade}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              message={
                this.state.unlockDone ? (
                  <span id="unlockdone">开门成功</span>
                ) : this.state.unlockError !== null ? (
                  <span id="unlockfail">开门失败,请重试</span>
                ) : (
                  <span id="unlockfail" />
                )
              }
            />

            <Dialog
              open={this.state.unlockProgress !== ""}
              onClose={this.handleClose}
              aria-labelledby="simple-dialog-title"
            >
              <div>
                <strong>请稍等...</strong>
              </div>
            </Dialog>
          </Grid>
        </Grid>
      );
    }
  }
}

EKey.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EKey);
